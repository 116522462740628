import React, {useState, useEffect} from 'react';
import { GoMute, GoUnmute } from "react-icons/go"
import BgVideoToggleButton from './BgVideoToggleButton';
import {MobileView, BrowserView} from "react-device-detect"


const BackgroundVideo = ({content, mute}) => {
    const useStyles ={
        btnHidden: "opacity-0 mt-10",
        btnShow: "relative mt-10 transition duration-1000 ease-out opacity-1",
    }
    const [muteIcon, setMuteIcon] = useState(false)
    const [muteStatus, setMuteStatus] = useState(true)
    const [buttonStatus, setButtonStatus] = useState('btnHidden')

    const btnRef = React.useRef()
    btnRef.current = buttonStatus

    const toggleMute = () => {
        setMuteStatus(!muteStatus); 
        setMuteIcon(!muteIcon);
        document.getElementById('videoId').play();
    
    }

    const setToMute = () => {
        setMuteStatus(true); 
        setMuteIcon(false);
        document.getElementById('videoId').play();
    
    }

    useEffect(() => {
        setTimeout(() => {
            setButtonStatus('btnShow')
        },4000)
    },[])

    return (
        <>
            <BrowserView>
                <div className='relative overflow-hidden'>
                    <video id="videoId" autoPlay playsInline loop muted={mute||muteStatus} style = {{
                        position: "absolute",
                        width: "100vw",
                        left: "50%",
                        top: "50%",
                        height: "100vh",
                        objectFit: "cover",
                        transform: "translate(-50%,-50%)",
                        opacity: "0.5",
                        zIndex: "-1"
                    }}><source src={content.backgroundVideo.file.url} type="video/mp4"/>
                    </video>
                    <header className="viewport-header">
                        <div className="h-screen flex flex-col justify-center items-center md:my-auto overflow-hidden">
                            <img className="w-24" src={content.svgImage.file.url} alt="herofire"/>
                            <div className="relative md:flex justify-center items-center z-10 w-full">
                                <svg viewBox="0 0 750 130" xmlns="http://www.w3.org/2000/svg" className="w-full my-2 md:my-0">
                                    <text x="50%" y="73%" textAnchor="middle" fill="#ffffff" className="november-text text-6halfxl leading-13 text-middle">{content.headline}</text>
                                    <text x="50%" y="73%" textAnchor="middle" fill="rgba(0,0,0,0)" className="text-outline hidden md:block tracking-wide text-6halfxl absolute text-center w-screen overflow-hidden">{content.headline}</text>
                                </svg>                    
                            </div>
                            <h3 className="text-xl mb-3 lg:text-3xl">{content.chineseHeadline}</h3>
                            <div className={`${useStyles[btnRef.current]}`}>
                                <BgVideoToggleButton href={content.hdBackgroundVideo.file.url} muteStatusCallback={setToMute}>{content.backgroundVideoButton}</BgVideoToggleButton>
                            </div>
                        </div>
                        <div className="absolute bottom-0 right-0" onClick={toggleMute}>
                            {
                                muteIcon ? <GoUnmute className="text-white h-4 w-auto m-4"/> : <GoMute className="text-white h-4 w-auto m-4"/>
                            }
                        </div>
                    </header>

                </div>
            </BrowserView>
            <MobileView>
                <div className='relative overflow-hidden'>
                    <video id="videoId" autoPlay playsInline loop muted={mute||muteStatus} style = {{
                        position: "absolute",
                        width: "100vw",
                        left: "50%",
                        top: "50%",
                        height: "100vh",
                        objectFit: "cover",
                        transform: "translate(-50%,-50%)",
                        opacity: "0.5",
                        zIndex: "-1"
                    }}><source src={content.backgroundVideoMobile.file.url} type="video/mp4"/>
                    </video>
                    <header className="viewport-header">
                        <div className="h-screen flex flex-col justify-center items-center md:my-auto overflow-hidden">
                            <img className="w-24" src={content.svgImage.file.url} alt="herofire"/>
                            <div className="relative md:flex justify-center items-center z-10 w-full">
                                <svg viewBox="0 0 750 130" xmlns="http://www.w3.org/2000/svg" className="w-full my-2 md:my-0">
                                    <text x="50%" y="73%" textAnchor="middle" fill="#ffffff" className="november-text text-6halfxl leading-13 text-middle">{content.headline}</text>
                                    <text x="50%" y="73%" textAnchor="middle" fill="rgba(0,0,0,0)" className="text-outline hidden md:block tracking-wide text-6halfxl absolute text-center w-screen overflow-hidden">{content.headline}</text>
                                </svg>                    
                            </div>
                            <h3 className="text-xl mb-3 lg:text-3xl">{content.chineseHeadline}</h3>
                            <div className={`${useStyles[btnRef.current]}`}>
                                <BgVideoToggleButton href={content.hdBackgroundVideo.file.url} muteStatusCallback={setToMute}>{content.backgroundVideoButton}</BgVideoToggleButton>
                            </div>
                        </div>
                        <div className="absolute bottom-0 right-0" onClick={toggleMute}>
                            {
                                muteIcon ? <GoUnmute className="text-white h-4 w-auto m-4"/> : <GoMute className="text-white h-4 w-auto m-4"/>
                            }
                        </div>
                    </header>
                </div>
            </MobileView>
        </>

    );
};

export default BackgroundVideo;


