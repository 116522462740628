import React, {useState, useRef, useEffect} from 'react';
import Button from '../Button';
import Img from "gatsby-image/withIEPolyfill";
import gsap, {Power1} from "gsap";
import CarouselFcMobile from "../carousel/CarouselFcMobile";
import BackgroundImage from 'gatsby-background-image'
import TranslateCN from "../../intl/cn.json"
import TranslateEN from "../../intl/en.json"
import {isBrowser, MobileView, BrowserView} from "react-device-detect"

const FeatureLandingNew = ({content, svg, mobileBg, locale}) => {
    let windowWidth;

    if (typeof window !== `undefined`) {
        windowWidth = window.innerWidth
    }

    const [activeIndex, setActiveIndex] = useState(0);
    const [layoutType, setLayoutType] = useState("3 image layout")
    const [timeline] = useState(gsap.timeline({paused: true}));
    const [width, setWidth] = useState(windowWidth);
    const breakpoint = 1024;



    let firstFadeEl = useRef(null);
    let secondFadeEl = useRef(null);
    let thirdFadeEl = useRef(null);
    let fadeTween = useRef(null);
    
    // define button locale
    let buttonTitle = {}
    if (locale === "zh-CN") {
            buttonTitle =  TranslateCN
        } else {
            buttonTitle = TranslateEN
    }


    const titles = [];
    content.forEach((item, i) => {
        titles.push(
                <text key={`fc-title-${i}`} id={`title-${i}`} x="0%" y={`${(16)*i+15}%`} fill="transparent" className={`cursor-pointer opacity-50 relative text-5xl md:text-4xl italic z-10 uppercase leading-none text-outline`} onClick={()=>{scaleDown(`#title-${activeIndex}`); scaleUp(`#title-${i}`); setActiveIndex(i);  setLayoutType(item.node.layoutType);}}>{item.node.title}</text>  
        )
    })

    function scaleUp(id){
        gsap.to(id, {scale: 1.35, 
            fill: "#fff", 
            cursor: "pointer", 
            transformOrigin: "left center",
            duration: 1, 
            opacity: 1,
            smoothOrigin: true})
    }
    

    function scaleDown(id){
        gsap.to(id, {scale:1, 
            fill: "transparent", 
            duration: 1,
            opacity: .5})

    }

    const desktopImages = [];
    content.forEach((node, index) => {
        desktopImages.push(content[index].node.desktop)
    })

    const mobileImages = [];
    content.forEach((node, index) => {
        mobileImages.push(content[index].node.mobile)
    })



    const Layout= ({images}) => {
        // console.log(images)
        if (isBrowser) {
            if (layoutType === "3 image layout with 1 small"){
                return(<ThreeImagePlusSmallLayout />)
            } else if (layoutType === "3 image layout"){
                return(<ThreeImageLayout />)
            } else if (layoutType === "2 image layout"){
                return(<TwoImageLayout />)
            } else return <div> </div>
        } else {
           return <CarouselFcMobile images={images} />
        }
    }

    const ThreeImagePlusSmallLayout = () => {
        if(content[activeIndex].node.featuredImages.length > 2 ){
            return (
                <div id="features-content-right-column" className="animate-translate grid col-start-5 col-span-5 grid-cols-10 gap-3 max-h-featured">
                    <div id = "img-wrapper-left-column" className="w-full grid grid-cols-3 gap-3 col-span-7 overflow-hidden">
                        <div id="horizontal-top" ref={el => (firstFadeEl = el)} className="col-span-3 h-full">
                            <Img className="max-w-full h-full" fluid={content[activeIndex].node.featuredImages[0].fluid}/>
                        </div>
                        <div id="horizontal-bottom" ref={el => (secondFadeEl = el)} className="col-end-4 col-span-1">
                            <Img className="max-h-screen h-full" fluid={content[activeIndex].node.featuredImages[1].fluid}/>
                        </div>
                    </div>
                    <div id="img-wrapper-right-column" className="col-span-3 overflow-hidden">
                        <div id="vertical" ref={el => (thirdFadeEl = el)} className="max-h-screen h-full">
                            <Img className="h-full" fluid={content[activeIndex].node.featuredImages[2].fluid} objectPosition="0% 50%" />
                        </div>
                    </div>
                </div>
            )
        } else {
            return <TwoImageLayout />
        }
    }

    const ThreeImageLayout = () => {
        if(content[activeIndex].node.featuredImages.length > 2 ){
            return(
                
                <div className="animate-translate grid grid-cols-10 gap-3 max-h-featured col-start-5 col-span-5">
                    <div id="img-wrapper-left-column" className="col-span-7"> 
                        <div id="vertical" ref={el => (firstFadeEl = el)} className="max-h-featured h-full overflow-hidden">
                            <Img className="max-h-featured h-full" fluid={content[activeIndex].node.featuredImages[0].fluid}/>
                        </div>
                    </div>
                    <div id="img-wrapper-right-column" className="col-span-3 grid gap-3 max-h-featured overflow-hidden"> 
                        <div id="horizontal-top" ref={el => (secondFadeEl = el)} className="col-span-5 grid gap-3">
                            <Img className="w-full" fluid={content[activeIndex].node.featuredImages[1].fluid}/>
                        </div>
                        <div id="horizontal-bottom" ref={el => (thirdFadeEl = el)} className="col-span-5 grid gap-3">
                            <Img className="w-full" fluid={content[activeIndex].node.featuredImages[2].fluid}/>
                        </div>
                    </div> 
                </div>
            )
        } else {
            return <TwoImageLayout />
        }
    }

    const TwoImageLayout = () => (
        <div className="animate-translate grid grid-cols-10 gap-5 col-start-5 col-span-5 max-h-featured items-end">
            <div id = "img-wrapper-left-column" className="col-span-6 overflow-hidden max-h-featured">
                <div id="horizontal" ref={el => (firstFadeEl = el)} >
                    <Img fluid={content[activeIndex].node.featuredImages[0].fluid}/>
                </div>
            </div>
            <div id="img-wrapper-right-column" className="grid col-span-4 overflow-hidden self-stretch">
                <div id="vertical" ref={el => (secondFadeEl = el)} className="h-full ">
                    <Img className="max-h-screen h-full" fluid={content[activeIndex].node.featuredImages[1].fluid} objectPosition="0% 50%" />
                </div>
            </div>
        </div>
    )

    const checkRenderSize = () => {
        return(
            <>
            <BrowserView>
                <div id="featured-section" className="w-screen h-screen overflow-hidden relative features-bg bg-opacity-25">
                    <div id="featured-content" className="grid gap-3 grid-cols-10 auto-rows-auto relative pt-24 h-screen my-0 mx-auto max-w-95/100">
                        <div id="featured-content-left-column" className="col-start-2 col-span-3 h-full"> 
                            <div id="featured-product-name" className=" w-full z-10 h-auto pb-12">
                                <svg viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg" className="w-full">
                                    {titles}
                                </svg>  
                            </div>
                            <div id="features-carousel-img" className="pl-6 relative w-2/3">
                                <img className="w-36 overflow-hidden absolute bottom-1/3 left-0 star-img z-0" src="https://images.ctfassets.net/yl9uwxtiezt9/5q60iQSwnEZVsWjb1k3Rs4/a317e642beb6d0161e678992ee992058/zipoo2.png?w=200&h=284&q=50&fm=webp" alt="starwhitefire"/>
                                    <Img className="max-h-featured product-img z-20" fixed={desktopImages[activeIndex].fixed} />
                                    <div className="pt-3">
                                        <Button href={`/${locale}/${desktopImages[activeIndex].title}`} fc={true}>{buttonTitle.general.button}</Button>
                                    </div>
                            </div>
                        </div>
                        <Layout />
                        <div id="features-ending" className="hidden lg:flex relative col-start-6 col-span-6 z-20 h-auto transform lg:-translate-y-12 xl:-translate-y-20">
                            <svg viewBox="0 0 800 250" xmlns="http://www.w3.org/2000/svg" className="w-full">
                                <text x="25%" y="60%" fill="rgba(0,0,0,0)" className="text-6xl text-outline absolute italic">INSPIRE</text>
                                <text x="20%" y="50%" fill="#ffffff" className="text-6xl text-inspire italic absolute">WE</text>
                            </svg>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <BackgroundImage
                    fluid={mobileBg.fluid}
                    style={{
                    // Defaults are overwrite-able by setting one or each of the following:
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div id="featured-section" className="w-screen overflow-hidden relative h-full">
                        <div id="featured-content" className="flex flex-col-reverse relative pt-16 my-0 mx-auto max-w-95/100">
                            <div id="featured-content-left-column" className="flex flex-row-reverse pt-32 pb-20 items-center md:pt-0 md:pb-0 h-halfscreen md:h-full"> 
                                <div id="featured-product-name" className="w-full z-10 h-auto pb-12">
                                    <svg viewBox="0 0 500 600" xmlns="http://www.w3.org/2000/svg" className="w-full x-2 md:mx-0">
                                        {titles}
                                    </svg>  
                                </div>
                                <div id="features-carousel-img" className="flex flex-col items-center relative w-2/3 pr-2">
                                    <img className={`w-28 overflow-hidden absolute transform-y-45 star-img z-0`} src="https://images.ctfassets.net/yl9uwxtiezt9/5q60iQSwnEZVsWjb1k3Rs4/a317e642beb6d0161e678992ee992058/zipoo2.png?w=200&h=284&q=50&fm=webp" alt="starwhitefire"/>
                                    {/* <div className="pl-8"> */}
                                        <Img className="product-img z-20" fixed={mobileImages[activeIndex].fixed} />
                                        <div className="pt-3">
                                            <Button href={`/${locale}/${desktopImages[activeIndex].title}`} fc={true}>{buttonTitle.general.button}</Button>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                            <Layout images={content[activeIndex].node.featuredImages} />
                        </div>
                    </div>
                </BackgroundImage>
            </MobileView>
            </>
        )
    }



    useEffect(() => {
        scaleUp(`#title-${activeIndex}`)
        window.addEventListener("resize", setWidth(window.innerWidth));
        // console.log("use effect 1", width)
        return(
            window.removeEventListener("resize", setWidth(window.innerWidth))
        )
    }, [windowWidth])

    useEffect(() => {
        if(isBrowser) {

            if (width < breakpoint){
                // for mobile and tablet view
                gsap.set([firstFadeEl], {opacity: 0, duration: 0});
                fadeTween.current = timeline
                            .to(firstFadeEl,{
                            opacity: 1,
                            ease: Power1.easeIn,
                            duration: .5
                        })

            } else {
                // for 2 image layout desktop view 
                if (content[activeIndex].node.featuredImages.length  < 3){
                    gsap.set([firstFadeEl, secondFadeEl], {opacity: 0, duration: 0});
                    fadeTween.current = timeline
                            .to(firstFadeEl,{
                                opacity: 1,
                                ease: Power1.easeIn,
                                duration: .8
                            })
                            .to(secondFadeEl,{
                                opacity: 1,
                                ease: Power1.easeIn,
                                duration: .8
                            })
                } else {
                // for 3 image layouts desktop view 
                    gsap.set([firstFadeEl, secondFadeEl, thirdFadeEl], {opacity: 0, duration: 0});
                    fadeTween.current = timeline
                    .to(firstFadeEl,{
                        opacity: 1,
                        ease: Power1.easeIn,
                        duration: .8
                    })
                    .to(secondFadeEl,{
                        opacity: 1,
                        ease: Power1.easeIn,
                        duration: .8
                    })
                    .to(thirdFadeEl,{
                        opacity: 1,
                        ease: Power1.easeIn,
                        duration: .8
                    })
                }
            }
            return() => fadeTween.current.pause().kill();
        }
    }, [activeIndex])

    useEffect(() =>{
        if (isBrowser) {
            fadeTween.current.play();
            return() => fadeTween.current.pause().kill();
        }
    }, [activeIndex])

    useEffect(() =>{
        gsap.fromTo(".star-img", {autoAlpha: 0,  y:20}, {autoAlpha: 1, zIndex: 0, y:0, delay:0.4, duration: 1})
        gsap.fromTo(".product-img", {autoAlpha: 0, scale: .8, y:20}, {autoAlpha: 1, scale: 1, y:0, duration: 1.25})
    }, [activeIndex])

    return (
            <>
                {checkRenderSize()}
            </>
    );
};

export default FeatureLandingNew;


