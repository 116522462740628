import React from "react"
import BackgroundImage from 'gatsby-background-image'
import Button from "../../components/Button"
import TranslateCN from "../../intl/cn.json"
import TranslateEN from "../../intl/en.json"


const Eshop = ({content, locale}) => {

  let buttonTitle = {}
    if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
  }
  
  return (
    <div>
      <BackgroundImage
      className="h-full"
      fluid={content.backgroundImage.fluid}
      >
        <div className="h-screen flex flex-col text-center mx-auto my-0 pb-20 items-center justify-center text-white">
          <h1 className="text-xl md:my-28 md:text-5xl font-black">E-SHOP</h1>
          <div className="md:text-10xl font-black leading-none uppercase mt-24 mb-10">
            <h1 className="text-5xl md:text-6xl lg:text-8xl text-outline">{content.header}</h1>
            <h1 className="text-5xl md:text-6xl lg:text-8xl pb-10">{content.subHeader}</h1>
          </div>
          <Button href={content.ctaLink}>{buttonTitle.general.button}</Button>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Eshop