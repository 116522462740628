import React from "react";
import * as THREE from "three";
import gsap, {Power1} from "gsap";
import { isMobileOnly, isTablet } from "react-device-detect";



class StarwrapThree extends React.Component { 
  constructor(props){
    super(props);
    this.imgs = isMobileOnly ? [this.props.data.firstImageMobile, this.props.data.secondImageMobile, this.props.data.thirdImageMobile]  : [this.props.data.firstImagePc, this.props.data.secondImagePc, this.props.data.thirdImagePc];
    this.distortion = this.props.data.distortionFilter;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.paused = true;
    this.scene = new THREE.Scene();
    this.imageGeom = new THREE.PlaneGeometry(1, 1, 2, 2);
    this.uniforms = {intensity: {value: 2., type:'f', min:0., max:3.}};
    this.imageUniforms = {
      intensity: { type:"f", value: 2. },
      // uAlpha: { type: "f", value: 0. },
      displacement: { type: "f", value: this.distortion.texture },
      texture1: { type: "f", value: "" },
      texture2: { type: "f", value: this.imgs[0].texture },
      time: { type: "f", value: 0. },
      progress: { type: "f", value: 0.},
      scaleX: { type: "f", value: 500. },
      scaleY: { type: "f", value: 500. },
      transition: { type: "f", value: 40. },
      resolution: { type: "v4", value: new THREE.Vector4() },
    };
    this.toggled = null;


    this.imageMaterial =  new THREE.ShaderMaterial({
      transparent: true,
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      uniforms: this.imageUniforms,
      vertexShader: `
        varying vec2 vUv;

        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        varying vec2 vUv;

        uniform float time;
        uniform float uAlpha;
        uniform float progress;
        uniform float intensity;
        uniform float scaleX;
        uniform float scaleY;
        uniform float transition;
        uniform vec4 resolution;
        uniform sampler2D displacement;
        uniform sampler2D texture1;
        uniform sampler2D texture2;

        mat2 getRotM(float angle) {
          float c = cos(angle);
          float t = tan(angle);
          float s = sin(angle);

          return mat2(s, -t, t, c);
        }

        const float PI = 3.1415;
        const float angle1 = -PI*0.9;
        const float angle2 = PI*0.9;
  
        void main() {
          vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
          
          vec4 disp = texture2D(displacement, newUV);
          vec2 dispVec = vec2(disp.r, disp.g);
          
          vec2 distortedPosition1 = newUV + getRotM(angle1) * dispVec * intensity * progress;
          vec4 t1 = texture2D(texture1, distortedPosition1);
          
          vec2 distortedPosition2 = newUV + getRotM(angle2) * dispVec * intensity * (1.0 - progress);
          vec4 t2 = texture2D(texture2, distortedPosition2);

          gl_FragColor = mix(t1, t2, progress);
        }
      `
      })

      this.plane = new THREE.Mesh(this.imageGeom, this.imageMaterial)

  }

  settings(){
    this.settings = {progress: 0.5}

    Object.keys(this.uniforms).forEach((item) => {
      this.settings[item] = this.uniforms[item].value;
    }) 
  }

  play() {
    this.paused = false;
  }

   // 2 create plane
   createPlane() {
    this.scene.add(this.plane)
  }

  componentDidMount() { 
    //create scene
    this.height  = this.mount.offsetHeight;
    this.width = this.mount.offsetWidth;

    //setup camera with facing upward
    this.camera = new THREE.PerspectiveCamera(60,this.mount.offsetWidth/this.mount.offsetHeight, 1, 1000);
    this.camera.position.z = 1;
    // this.camera.rotation.x = Math.PI/2;
    

    //setup renderer
    this.renderer = new THREE.WebGLRenderer({antialias:true, alpha: true});
    this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    this.renderer.setClearColor(0x000000, 0);
    this.mount.appendChild(this.renderer.domElement)

    //STARS ANIMATION
    //define line count
    let LINE_COUNT = 10000;
    let geom = new THREE.BufferGeometry();
    geom.setAttribute("position", new THREE.BufferAttribute(new Float32Array(6*LINE_COUNT),3));
    geom.setAttribute("velocity", new THREE.BufferAttribute(new Float32Array(2*LINE_COUNT),1));
    let pos = geom.getAttribute("position");
    let pa = pos.array;
    let vel = geom.getAttribute('velocity');
    let va = vel.array;

    for (let line_index= 0; line_index < LINE_COUNT; line_index++) {
        var x = Math.random() * 400 - 200;
        var y = Math.random() * 200 - 100;
        var z = Math.random() * 500 - 100;
        var xx = x;
        var yy = y;
        var zz = z;
        //line start
        pa[6*line_index] = x;
        pa[6*line_index+1] = y;
        pa[6*line_index+2] = z;
        //line end
        pa[6*line_index+3] = xx;
        pa[6*line_index+4] = yy;
        pa[6*line_index+5] = zz;

        va[2*line_index] = va[2*line_index+1]= 0;
    }

    let mat = new THREE.LineBasicMaterial({color: 0xffffff});
    let lines = new THREE.LineSegments(geom, mat);
    this.scene.add(lines);

    this.animate = function () {
      for (let line_index= 0; line_index < LINE_COUNT; line_index++) {
          va[2*line_index] += 0.01; //bump up the velocity by the acceleration amount
          va[2*line_index+1] += 0.0095;

          //pa[6*line_index]++;                       //x Start
          //pa[6*line_index+1]++;                     //y
          pa[6*line_index+2] += va[2*line_index];     //z
          //pa[6*line_index+3]++;                     //x End
          //pa[6*line_index+4]                        //y
          pa[6*line_index+5] += va[2*line_index+1];   //z

          if(pa[6*line_index+5] > 200) {
              var z= Math.random() * 200 - 100;
              pa[6*line_index+2] = z;
              pa[6*line_index+5] = z;
              va[2*line_index] = 0;
              va[2*line_index+1] = 0;
          }
      }
      pos.needsUpdate = true;
      requestAnimationFrame(this.animate.bind(this));

      // for updating progress value in hover animation 
      if (this.paused) return;
      // imageMaterial.uniforms.progress.value += 0.05;

      Object.keys(this.uniforms).forEach((item)=> {
       this.imageMaterial.uniforms[item].value = this.settings[item];
      });
  
      this.renderer.render(this.scene, this.camera);
  }

  


  // HOVER ANIMATION
  // 1 load texture and material 
  const loader = new THREE.TextureLoader();
  const that = this;
  
  setUpImgs(() => {
    window.addEventListener('resize', this.onWindowResize.bind(this), false)
    this.settings();
    this.createPlane();
    this.resize();
    this.play();
    this.animate();
  })


  function setUpImgs(cb){
    let promises = [];

    promises.push(
      loadTexture(loader, that.distortion.fluid.src ? that.distortion.fluid.src : null, 0)
    )  

    that.imgs.forEach((img, index) =>{
      promises.push(
        loadTexture(loader, img.fluid.src ? img.fluid.src : null, index+1)
      )
    })

    Promise.all(promises).then((promises) => { 
      promises.forEach((promise, index) => {
        // assign texture to item
        if (index === 0){
          that.distortion.texture = promise.texture
         } else {
           that.imgs[index-1].texture = promise.texture
         }
        }
      )
      cb()
    })
  }

  function loadTexture(loader, src, index){
    return new Promise((resolve, reject) => {
      if(!src){
        resolve({texture: null, index});
        return
      } 
      loader.load(
        src, 
        texture => {
          resolve({texture, index})
        },
        undefined, 
        err => {
          console.log ("error loading texture for hover images", err);
          reject(err)
        }
      )
    }
  )}
}



  transitionIn(index) {
    // if(this.isRunning) return;
    this.isRunning = true;
    let nextTexture = this.imgs[index].texture;
    this.imageMaterial.uniforms.displacement.value = this.distortion.texture;
    this.imageMaterial.uniforms.texture2.value = nextTexture;
    gsap.to(this.imageMaterial.uniforms.progress, 
      { duration: .5, 
        value: 1, 
        ease: Power1.easeIn,
        onComplete:()=>{
          this.isRunning = false;
        }
      }
    ) 
  }


  transitionOut(callback) { 
    this.isRunning = true;

    if(this.imageMaterial.uniforms.progress.value === 1){
      gsap.to(this.imageMaterial.uniforms.progress, 
        { duration: .5, 
          value: 0, 
          ease: Power1.easeIn,
          onComplete:()=>{
            console.log("")
          }
        }
      )
    } else {
    }

    callback();
  }

  toggleMenuAnim(index, title, subtitle){

    this.transitionOut(
      () => {
        if(this.toggled){
          this.exitBg("#salute-text h3", "#salute-text p","#star-white-fire");
          this.exitBg("#spark-text h3", "#spark-text p", "#star-white-fire");
          this.exitBg("#indefinite-text h3", "#indefinite-text p", "#star-white-fire");
        } else {
          this.transitionIn(index); 
          this.enterBg(title, subtitle, "#star-white-fire");
        }
        this.isRunning = false;
        this.toggled = !this.toggled;
      }
    )
  };
  

  enterBg(text, subtext, whitefire){
    gsap.to(text, {css:{color:"#fff", opacity: 1}, duration: .5})
    gsap.to(subtext, {opacity: 1, duration: .5})
    gsap.to(whitefire, {opacity: 0, duration: .5})
  }

  exitBg (text, subtext, whitefire){
      gsap.to(text, {css:{color:"rgba(0,0,0,0)", opacity: .5}, duration: .5})
      gsap.to(subtext, {opacity: 0.5, duration: .5})
      gsap.to(whitefire, {opacity: 1, duration: .5})
    }



  resize() {
    this.height = this.mount.offsetHeight;
    this.width = this.mount.offsetWidth;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect =(this.width/ this.height);
    const imageAspect = this.imgs[0].fluid.aspectRatio;
    let a1; let a2;

    // image contain - all image aspect ratios need to be the same
    if (isTablet){
      // if screen aspect ratio is higher than image aspect ratio, set image width to screen width
      if(this.height/this.width > imageAspect) {
        a1 = (this.width/ this.height) * imageAspect ;
        a2 = 1;
      } else{
        a1 = 1;
        a2 = (this.height/this.width) / imageAspect;
      }
    } else {
      // if screen aspect ratio is higher than image aspect ratio, set image width to screen width
      if(this.height/this.width > imageAspect) {
        a1 = (this.width/ this.height) / imageAspect ;
        a2 = 1;
      } else{
        a1 = 1;
        a2 = (this.height/this.width) * imageAspect;
      }
    }
    

    this.imageMaterial.uniforms.resolution.value.x = this.width;
    this.imageMaterial.uniforms.resolution.value.y = this.height;
    this.imageMaterial.uniforms.resolution.value.z = a1;
    this.imageMaterial.uniforms.resolution.value.w = a2;

    const dist  = this.camera.position.z;
    this.height = 1;
    this.camera.fov = 2*(180/Math.PI)*Math.atan(this.height/(2*dist));

    this.plane.scale.x = this.camera.aspect;
    this.plane.scale.y = 1;

    this.camera.updateProjectionMatrix();

  }

  onWindowResize(e) {
    e.preventDefault();

    if (this.mount) {
      this.camera.aspect = this.mount.offsetWidth / this.mount.offsetHeight
      this.resize();
      this.camera.updateProjectionMatrix()

    } else {
      
    }
  } 
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize.bind(this), false)
}


  render() {

    const MenuItems = () => {
      if(isMobileOnly){
        return(
          <div className="flex flex-col pt-12 w-full z-50 text-white my-4 justify-center">
            <div id="salute-text" className="py-2 text-center cursor-pointer mx-3" onClick={() => {this.toggleMenuAnim(0, "#salute-text h3", "#salute-text p")}} >
                <h3 className="text-xl text-outline opacity-50">{this.props.data.firstMenu}</h3>
                <p className="text-xs pt-1 opacity-50"><strong>{this.props.data.firstSubMenu}</strong></p>
            </div>
            <div id="spark-text" className="py-2 text-center cursor-pointer mx-3" onClick={() => {this.toggleMenuAnim(1, "#spark-text h3", "#spark-text p")}} >
                <h3 className="text-xl lg:text-3xl text-outline opacity-50">{this.props.data.secondMenu}</h3>
                <p className="text-xs pt-1 opacity-50"><strong>{this.props.data.secondSubmenu}</strong></p>
            </div>
            <div id="indefinite-text" className="py-2 text-center cursor-pointer mx-3" onClick={() => {this.toggleMenuAnim(2, "#indefinite-text h3", "#indefinite-text p")}}>
                <h3 className="text-xl lg:text-3xl text-outline opacity-50">{this.props.data.thirdMenu}</h3>
                <p className="text-xs pt-1 opacity-50"><strong>{this.props.data.thirdSubmenu}</strong></p>
            </div>
          </div>
        )
      } else {
        return (
          <div className="flex flex-row w-full z-50 text-white my-4 justify-center pt-32 lg:pt-0 lg:pb-32">
            <div id="salute-text" className="py-2 lg:py-0 text-center cursor-pointer mx-3" onMouseEnter={() => {this.toggleMenuAnim(0, "#salute-text h3", "#salute-text p")}} onMouseLeave={() => this.toggleMenuAnim(3, "#salute-text h3", "#salute-text p")} >
                <h3 className="text-xl lg:text-3xl text-outline opacity-50">{this.props.data.firstMenu}</h3>
                <p className="text-xs pt-1 lg:pt-3 opacity-50"><strong>{this.props.data.firstSubMenu}</strong></p>
            </div>
            <div id="spark-text" className="py-2 lg:py-0 text-center cursor-pointer mx-3" onMouseEnter={() => {this.toggleMenuAnim(1, "#spark-text h3", "#spark-text p")}} onMouseLeave={() => {this.toggleMenuAnim(2, "#spark-text h3", "#spark-text p")}} >
                <h3 className="text-xl lg:text-3xl text-outline opacity-50">{this.props.data.secondMenu}</h3>
                <p className="text-xs pt-1 lg:pt-3 opacity-50"><strong>{this.props.data.secondSubmenu}</strong></p>
            </div>
            <div id="indefinite-text" className="py-2 lg:py-0 text-center cursor-pointer mx-3" onMouseEnter={() => {this.toggleMenuAnim(2, "#indefinite-text h3", "#indefinite-text p")}} onMouseLeave={() => {this.toggleMenuAnim(0, "#indefinite-text h3", "#indefinite-text p")}}>
                <h3 className="text-xl lg:text-3xl text-outline opacity-50">{this.props.data.thirdMenu}</h3>
                <p className="text-xs pt-1 lg:pt-3 opacity-50"><strong>{this.props.data.thirdSubmenu}</strong></p>
            </div>
          </div>
        )
      }
    }

    return (
      <div id="star-wrap-section" ref={ref => (this.mount = ref)} className="star-wrap-bg" style={{ width: `100vw`, height: `100vh`, position: `relative`, overflow: `hidden`}} >
        <div className="flex flex-col justify-start content-center w-screen h-full pt-32 md:mt-64 lg:mt-48 absolute">
          <img id="star-white-fire" className=" w-20 mx-auto my-3" src="https://images.ctfassets.net/yl9uwxtiezt9/5q60iQSwnEZVsWjb1k3Rs4/a317e642beb6d0161e678992ee992058/zipoo2.png" alt="starwhitefire"/>
          <div className=" text-white text-center overflow-hidden font-bold text-xxs md:text-xs md:leading-7 my-3 w-full">
              <p><strong>{this.props.data.kvText[0]}</strong></p>
              <p><strong>{this.props.data.kvText[1]}</strong></p>
              <p><strong>{this.props.data.kvText[2]}</strong></p>
          </div>
          <MenuItems />
          {/*<div className="block left-1/2 bottom-0 md:hidden absolute center-translate-button">
            <Button href={"#"} color={`text-white border-white`} >Learn more</Button>
          </div>*/}
        </div>
      </div>
    )
  }
}

export default StarwrapThree