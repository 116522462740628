import React from 'react';
import TimelineItem from './TimelineItem';

import './Timeline.css'

const Timeline = ({articles, parentCallback, locale}) => {
    return (
        <div className="timeline-container">
            <span className="white-line"></span>
            {articles.map((data, index) => (
                <TimelineItem locale={locale} data={data} key={index} index={index} parentCallback={parentCallback}/>
            ))}
        </div>
    );
};

export default Timeline;