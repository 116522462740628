import React, {useRef} from 'react';
import gsap, {Elastic} from 'gsap';

const TimelineItem = ({data, index, parentCallback, locale}) => {
    let opacityEl = useRef(null)

    function incrementOpacity() {
      const el = (opacityEl.children[0].childNodes[1]);
      gsap.to(el, {duration: .5, opacity: 1, ease: Elastic})
    } 

    function decrementOpacity() {
        const el = (opacityEl.children[0].childNodes[1]);
        gsap.to(el, {duration: .5, opacity: .5, ease: Elastic})
      } 

    // warning do not change the html structure below, useRef depends on it
    return (
            <div className="timeline-item" ref={el => opacityEl = el}>
                {data.node.slug && (
                    <a
                        href={`/${locale}/news/${data.node.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={()=>parentCallback(index)} 
                        onMouseOver={() =>incrementOpacity()} 
                        onMouseLeave={() => decrementOpacity()}
                        onClick={() => decrementOpacity()}
                        onFocus={() =>incrementOpacity()}
                    >
                        <span className="circle"></span>
                        <time id={index} className={index} onClick={(event)=>{event.preventDefault();parentCallback(index)}} >{data.node.publishedDate}</time>
                    </a>
                )}
            </div>
    );
};

export default TimelineItem;