import React, {useState} from 'react';
import Timeline from '../Timeline';
import { isMobileOnly} from "react-device-detect";
import TranslateCN from "../../intl/cn.json"
import TranslateEN from "../../intl/en.json"


const News = ({content, locale}) => {
    //update title index on hover of child component TimelineItem's bullet point
    const [titleIdx,setTitleIdx] = useState(0)

    const callback = (index) => {
        setTitleIdx(index)
    }

    let buttonTitle = {}
    if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
    }

    let desktopTitle1
    let desktopTitle2
    if (isMobileOnly && locale === "zh-CN") {
      desktopTitle1 = buttonTitle.landing.newsTitle
      desktopTitle2 = ""
    } else {
      const splitNewsTitle = buttonTitle.landing.newsTitle.split(" ")
      desktopTitle1 = splitNewsTitle[0]
      desktopTitle2 = splitNewsTitle[1]
    }

    return (
      <div id="current-news-section"  className="relative h-screen w-screen news-bg z-0 my-auto overflow-hidden">
          <div className="flex flex-col text-center mx-auto items-center justify-start md:justify-center text-white w-screen h-screen">
          {isMobileOnly ? 
            <h3 className='font-bold leading-10 text-3xl pt-16'>{buttonTitle.landing.newsTitle}</h3> 
            : 
            <h3 className='font-bold leading-10 md:leading-header text-3xl md:text-5xl pt-24'>{desktopTitle1}<br/>{desktopTitle2}</h3>
          }
            <div className="pb-3 mt-6 h-1/8 lg:h-auto lg:mt-12 relative w-3/5">
              <h3 className="text-3xl md:text-5xl absolute left-0 top-0 opacity-50">“</h3>
              <h3 className="md:w-4/5 my-0 md:my-4 mx-auto px-4 text-lg uppercase">{`${content[titleIdx].node.title}`}</h3>
              <h3 className="text-3xl md:text-5xl absolute right-0 top-0 opacity-50">”</h3>
            </div>
            <div className="timeline md:text-4xl uppercase pb-3">
              <Timeline locale={locale} articles={content} parentCallback={callback}/>
            </div>
          </div>
        {/* </div> */}
      </div>
    );
};

export default News;