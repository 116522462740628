import React from "react"
import BackgroundImage from 'gatsby-background-image'
import BackgroundVideo from "../BackgroundVideo"


const HeroLanding = ({hero, muteStatus}) => {

  const videoCheck = () => {
    if(hero.activateBackgroundVideo) {
      return <BackgroundVideo mute={muteStatus} content={hero}/>
    } else {
      return (
        <BackgroundImage
        id="hero-section"
        className="h-full"
        fluid={hero.backgroundImage.fluid}
        >
        <header className="viewport-header">
            <div className="h-full flex flex-col justify-center items-center md:my-auto overflow-hidden py-6">
                <img className="w-20" src={hero.svgImage.file.url} alt="herofire"/>
                <div className="relative md:flex justify-center items-center z-10 w-full">
                    <svg viewBox="0 0 750 100" xmlns="http://www.w3.org/2000/svg" className="w-full my-4 md:mb-0 md:mt-4 border-box">
                        <text x="50%" y="75%" textAnchor="middle" fill="#ffffff" className="november-text text-6halfxl leading-10 tracking-wide text-middle">{hero.headline}</text>
                        <text x="50%" y="73%" textAnchor="middle" fill="rgba(0,0,0,0)" className="text-outline hidden md:block tracking-wider text-6halfxl absolute text-center w-screen overflow-hidden">{hero.headline}</text>
                    </svg>                    
                </div>
                <h3 className="text-xl lg:text-2xl">{hero.chineseHeadline}</h3>
            </div>
        </header>
        </BackgroundImage>
      )

    }
  }

  return (
    <>
      {videoCheck()}
    </>
       
  )
}

export default HeroLanding