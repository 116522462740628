import React, {useRef, useState} from 'react';
import Swiper from 'react-id-swiper';
import Img from 'gatsby-image'
import "./CarouselFcMobile.css";
import 'swiper/css/swiper.css';
import {isMobileOnly} from "react-device-detect";


const CarouselFcMobile = ({images}) => {

     // swiper config
     const params = {
        containerClass: 'swiper-container-fc' ,
        mousewheel: {
            forceToAxis: true,
        }, 
        effect: "fade",
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    }


    return (
        <Swiper {...params}>
            {images.map((image, i) => {
                return(
                    <Img style={{height: isMobileOnly ? "calc(var(--vh, 1vh) * 35)" : "calc(var(--vh, 1vh) * 40)", width: isMobileOnly ? "100%" : "90%", objectFit:"contain"}} key={`fc-image-${i}`} fluid={image.fluid} />
                )
            })}
        </Swiper>
    )
};
export default CarouselFcMobile;
