import PropTypes from "prop-types"
import React from "react"

const BgVideoToggleButton = ({ children, href, muteStatusCallback, ...params }) => {
  const fullScreen = (href) => {
    if (typeof window !== "undefined") {
      window.open(href,'NewWindow','fullscreen=yes');
    }

  }
  let className =
    `inline-block font-normal px-4 xl:px-8 py-2 text-xs border border-1 text-white border-white bg-transparent hover:bg-white hover:bg-opacity-25 focus:outline-none transition duration-150 ease-in-out centralesans-bold`

  if (href) {
    return (
      <div onClick={()=> {muteStatusCallback(); fullScreen(href)}}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className={className}
        >
          <span>{children}</span>
        </a>
      </div>
    )
  } else {
    return (
      <button className={className} {...params}>
        {children}
      </button>
    )
  }
}

BgVideoToggleButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

BgVideoToggleButton.defaultProps = {
  href: null,
}

export default BgVideoToggleButton
