import React from "react"
import BackgroundImage from 'gatsby-background-image'
import Button from "../Button"
import TranslateCN from "../../intl/cn.json"
import TranslateEN from "../../intl/en.json"
import { BsChevronDoubleDown } from "react-icons/bs"
import { isMobileOnly } from "react-device-detect"

const SeriesLanding = ({content, locale}) => {
  let buttonTitle = {}
    if (locale === "zh-CN") {
        buttonTitle =  TranslateCN
    } else {
        buttonTitle = TranslateEN
  }

  return(    
    <BackgroundImage
      className="h-full"
      fluid={content.node.backgroundImage.fluid}
      style={{
        // Defaults are overwrite-able by setting one or each of the following:
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
        <header className="viewport-header">
            <div className="flex flex-col justify-center items-center md:my-auto overflow-hidden py-6 rounded-gradient" style={{height: `calc(var(--vh, 1vh) * 100)`}} >
                <div className="relative md:flex justify-center items-center z-10 w-full">
                    <svg viewBox="0 0 1200 100" xmlns="http://www.w3.org/2000/svg" className="w-full my-4 md:mb-0 md:mt-4 border-box">
                        <text x="50%" y="90%" textAnchor="middle" fill="rgba(0,0,0,0)" className="text-outline tracking-wide text-7xl md:text-6halfxl absolute text-center w-screen overflow-hidden">{content.node.header}</text>
                    </svg>                    
                </div>
                <h3 className="text-xl lg:text-6xl mb-8 lg:mb-12">{content.node.subHeader}</h3>
                <Button href={`/${locale}${content.node.ctaLink}`}>{buttonTitle.general.button}</Button>
            </div>
        </header>
    </BackgroundImage>
  )
  
}

export default SeriesLanding